// MobileNav.scss


.mobile-navbar {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #FDF3DD;
    padding: 10px;
    display: flex;
    justify-content: space-between;


    .mobile-navbar-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        }

    .logo {
      height: 50px;
      margin-right: auto; // This will push the logo to the left

    }

    .menu-icon {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 25px;
      height: 20px;
      margin-left: auto; // This will push the menu icon to the right


      .bar {
        width: 25px;
        height: 3px;
        background-color: #373D3B;
        transition: transform 0.3s, opacity 0.3s;
      }

      &.open {
        .bar:first-child {
          transform: translateY(6px) rotate(-45deg);
        }

        .bar:nth-child(2) {
          opacity: 0;
        }

        .bar:last-child {
          transform: translateY(-6px) rotate(45deg);
        }
      }
    }

    .menu-dropdown {
        display: flex;
      flex-direction: column;
      position: absolute;
      top: 50px;
      right: 0;
      background-color: #F1DFB6;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      z-index: 99;

      &.open {
        display: flex;
      }

      .navbar-link {
        text-decoration: none;
        color: #371f1f;
        padding: 10px;
        font-weight: bold;

        &:hover {
          background-color: #F1DFB6;
          color: #EF601E;
        }
      }
    }

  }
