/* ComingSoon.scss */
$background-color: #ffefef;
$text-color: #333;

.coming-soon-body {
  background-color: $background-color;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.coming-soon-container {
  text-align: center;
  color: $text-color;

  h2 {
    font-size: 32px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
  }
}
