// Navbar.scss
.desktopnav {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #FDF3DD;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    height: 50px;
  }

  .menu-items {
    display: flex;

    a {
      text-decoration: none;
      color: #371f1f;
      margin: 0 10px;
      font-weight: bold;
    }
  }

  .navbar-link {
    text-decoration: none;
    color: #371f1f;
    margin: 0 10px;
    font-weight: bold;
    transition: color 0.3s;
  }

  /* Hover state */
  .navbar-link:hover {
    color: #CB962E;
  }