// Resume.scss
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Poppins:ital@0;1&display=swap');

.resume-container {
    display: flex;
    flex-direction: row;
    min-height: fit-content;
    overflow-y: auto;
  }

  .resume-content {
    flex: 3;
    background-color: #ffefef;
    padding: 20px;
    color: #371f1f;
    height: fit-content;
    padding-bottom: 30px;
  }

  .resume-secondary {
    flex: 1; /* Right column takes less space */
    background-color: #eff8f8;
    padding: 20px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
  }

  .resume-image img {
    width: -webkit-fill-available;
  }

h1, h2, h3, h4, h5, h6 {
    font-family: 'Archivo Black', sans-serif;
    font-weight: 700;
    color: #371f1f;
  }

  body {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #371f1f;
  }

/* Media query for mobile layout */
@media (max-width: 768px) {
  .resume-container {
    flex-direction: column; /* Ensure components stack on mobile */
  }

  .resume-content,
  .resume-secondary {
    max-width: none; /* Remove max-width on mobile to allow full width */
  flex: none;
  overflow: visible;
  }
}