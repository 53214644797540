body, html, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }

  .app-body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }