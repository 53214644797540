/* Home.scss */
$background-color: #FDF3DD;
$text-color: #333;
$text-link-color: #FDF3DD;

.home-body {
  background-color: $background-color;
  margin: 0;
  display: flex;
  align-items: center;
  height: 100vh;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 30px;
  }
}

.profile-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.profile-image {
  max-width: 600px;
  width: 60%;
  height: auto;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 80%;
    margin-bottom: 20px;
  }
}

.right-container {
    display: grid;
    flex-direction: column;
    min-width: 300px;
    margin-left: auto;
    margin-right: 100px;
    box-sizing: border-box;

    @media (max-width: 768px) {
      margin: 0;
      width: 100%;
      padding: 10px;
    }
  }

  .box {
    width: 100%;
    padding: 10px;
    margin: 5px;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    cursor: pointer;

    &:nth-child(odd) {
      background-color: #cb962e;
      justify-self: flex-end;

      a {
        color: #FDF3DD;
      }
    }

    &:nth-child(even) {
      background-color: #F1DFB6;
      justify-self: flex-start;

      a {
        color: #371f1f;
      }
    }

    @media (max-width: 768px) {
      text-align: left;
    }

    a {
        text-decoration: none;
        font-family: 'DM Sans', system-ui;
        font-weight: bold;
      }
  }

