// AboutMe.scss
.about-me-container {
    display: flex;
    align-items: center;
    background-color: #FDF3DD;
    height: 100vh;
    flex: 1;
  }


  .text-container {
    width: 50%;
    padding: 20px;
    background-color: #CB962E;
    color: #FDF3DD;
    margin: 20px;
  }



  .profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin-bottom: 20px
  }

  .profile-image-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .social-icons {
    display: flex;
    justify-content: space-around;
    width: 80%;

    a {
      color: #371f1f;

      svg {
        font-size: 24px;
        cursor: pointer;

        &:hover {
          color: #CB962E;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .profile-container {
      margin-left: 20px; // Add some margin to separate the columns
    }
  }



  @media (max-width: 768px) {
    .about-me-container {
      flex-direction: column;
      text-align: center;
    }

    .text-container {
      width: 80%;
    }

    .profile-container {
        width: 80%;
      }
    }